<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top" v-if="isXian">
      <div class="top-select">
        <el-form :model="SerachInput" label-width="80px">
          <el-form-item label="发送时间">
            <el-col>
              <el-date-picker
                class="in1"
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
                style="width: 200px"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
              -
              <el-date-picker
                class="in2"
                v-model="value2"
                type="datetime"
                placeholder="选择日期时间"
                style="width: 200px"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="接收产品名">
            <!-- <el-select
              v-model="SerachInput.region"
              placeholder="请选择活动区域"
              clearable
              @change="pruList"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select> -->
            <el-select
              v-model="SerachInput.region"
              @change="pruList"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in unitName"
                :key="item.key"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
              <el-pagination
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChanges"
                :page-size="pageSizes"
                :current-page="pageCounts"
                layout="total, prev, pager, next"
                :total="unitNamePage"
                @pagination="getSelectList"
              >
              </el-pagination>
            </el-select>
          </el-form-item>
          <el-form-item label="消息类型" style="width: 20%">
            <el-select
              v-model="SerachInput.regions"
              placeholder="请选择活动区域"
              clearable
              @change="messLei"
            >
              <el-option
                v-for="item in typeLists"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消息ID">
            <el-input
              v-model="SerachInput.phones"
              placeholder="请输入内容"
              style="width: 150px"
              clearable
              @change="changeId"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="20px">
            <img
              src="../../assets/images/query.png"
              alt=""
              style="width: 49px"
              @click="handleQuery"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          ref="multipleTable"
          style="width: 100%"
        >
          <el-table-column prop="id" label="消息ID" width="100">
          </el-table-column>
          <el-table-column prop="type" label="消息类型" width="130">
          </el-table-column>
          <el-table-column prop="productName" label="产品分类" width="130">
          </el-table-column>
          <el-table-column prop="groupNum" label="销售分组" width="130">
          </el-table-column>
          <el-table-column prop="category" label="消息分类" width="130">
          </el-table-column>
          <el-table-column prop="title" label="消息标题" width="130">
          </el-table-column>
          <el-table-column
            prop="content"
            label="消息内容"
            width="350"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                size="small"
                type="text"
                @click="rowClick(scope.$index, scope.row)"
                ><span
                  v-if="scope.row.type === '音频消息'"
                  @click="rowPlay(scope.$index, scope.row)"
                  >{{ scope.row.content }}</span
                >
                <span v-else>{{ scope.row.content }}</span>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="sendDate" label="推送时间" width="150">
          </el-table-column>
          <el-table-column prop="state" label="启用" width="130">
            <template slot-scope="scope">
              <el-button
                size="mini"
                style="font-size: 14px; color: #606266; font-weight: 400"
                @click="nameUser(scope.$index, scope.row)"
                >{{ scope.row.state }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="status" label="操作" width="130">
            <template slot-scope="scope">
              <el-button @click="details(scope.$index, scope.row)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <el-button type="primary" @click="addLabel">增加</el-button>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>数据分析</el-button>
            <el-button @click="dataExport">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          style="padding: 12px 19px"
        >
          <!-- <el-form-item label="产品分类:">
            <el-select
              v-model="formInline.productId"
              placeholder="请选择"
              @change="pruList"
            >
              <el-option
                v-for="item in typeListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="产品分类:">
            <el-select
              v-model="formInline.productId"
              @change="pruList(formInline.productId)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in unitName"
                :key="item.key"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
              <el-pagination
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChanges"
                :page-size="pageSizes"
                :current-page="pageCounts"
                layout="total, prev, pager, next"
                :total="unitNamePage"
                @pagination="getSelectList"
              >
              </el-pagination>
            </el-select>
          </el-form-item>
          <el-form-item label="消息类型:">
            <el-select
              v-model="formInline.type"
              placeholder="请选择"
              @change="messLei"
            >
              <el-option
                v-for="item in typeListname"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售分组:">
            <el-select
              v-model="formInline.groupId"
              placeholder="请选择"
              multiple
              @change="groupLei"
              @input="$forceUpdate()"
              style="width: 390px"
            >
              <el-option label="全部设备" :value="groupIdOne"></el-option>
              <el-option
                label="未分组设备"
                :value="groupIdTwo"
                :disabled="isNo"
              ></el-option>
              <el-option
                v-for="item in typeListGroup"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="isNo"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消息分类:">
            <el-select
              v-model="formInline.category"
              placeholder="请选择"
              @input="$forceUpdate()"
            >
              <el-option
                v-for="item in typeListMessage"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消息标题:">
            <el-input
              v-model="formInline.title"
              placeholder="请输入内容"
              style="width: 390px"
            ></el-input>
          </el-form-item>
          <el-form-item label="发送时间:">
            <el-date-picker
              class="in1"
              v-model="value3"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 200px"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="消息内容:">
            <el-input
              v-model="formInline.text"
              placeholder="请输入内容"
              style="width: 390px"
              @input="$forceUpdate()"
              @change="$forceUpdate()"
              id="conXiao"
              :disabled="disabled"
            ></el-input>
            <el-button
              type="primary"
              plain
              @click="pushImage"
              v-if="isShow"
              style="margin-left: 20px; color: #fff"
              >上传</el-button
            >
          </el-form-item>
          <el-form-item label="启用状态:">
            <el-select
              v-model="formInline.state"
              placeholder="请选择"
              @change="$forceUpdate()"
              @input="$forceUpdate()"
            >
              <el-option
                v-for="item in statusOne"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel" style="padding: 12px 20px"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="preservation"
            style="padding: 12px 20px; font-size: 14px; border-radius: 4px"
            >保 存</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 增加上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible"
      width="30%"
      :before-close="handleCloseImage"
      append-to-body
    >
      <input
        type="file"
        id="addTu"
        ref="file"
        accept="image/*"
        @change="fileChange"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 增加上传音频弹框 -->
    <el-dialog
      title="上传音频"
      :visible.sync="audioFrequency"
      width="30%"
      :before-close="handleCloseaAuFre"
      append-to-body
    >
      <input
        type="file"
        ref="audio"
        accept=".amr"
        capture="microphone"
        id="pop_audio"
      />
      <!-- <audio id="audioId" controls width="100%"></audio> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="audioFrequency = false">取 消</el-button>
        <el-button type="primary" @click="uploadAudio">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 增加上传视频弹框 -->
    <el-dialog
      title="上传音频"
      :visible.sync="videoFrequency"
      width="30%"
      :before-close="handleCloseVideFre"
      append-to-body
    >
      <input
        type="file"
        ref="video"
        id="addShi"
        accept="video/*"
        capture="camcorder"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="videoFrequency = false">取 消</el-button>
        <el-button type="primary" @click="uploadVideo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改的弹框 -->
    <div>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisibleChange"
        width="50%"
        :before-close="handleClose"
      >
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          style="padding: 12px 19px"
        >
          <!-- <el-form-item label="产品分类:">
            <el-select
              v-model="formInline.productId"
              placeholder="请选择"
              @change="pruList"
            >
              <el-option
                v-for="item in typeListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="产品分类:">
            <el-select
              v-model="formInline.productId"
              @change="pruList(formInline.productId)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in unitName"
                :key="item.key"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
              <el-pagination
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChanges"
                :page-size="pageSizes"
                :current-page="pageCounts"
                layout="total, prev, pager, next"
                :total="unitNamePage"
                @pagination="getSelectList"
              >
              </el-pagination>
            </el-select>
          </el-form-item>
          <el-form-item label="消息类型:">
            <el-select
              v-model="formInline.type"
              placeholder="请选择"
              @change="messLei"
            >
              <el-option
                v-for="item in typeListname"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发送时间:">
            <el-date-picker
              class="in1"
              v-model="value4"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 200px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="销售分组:">
            <el-select
              v-model="formInline.groupId"
              placeholder="请选择"
              multiple
              @change="groupLei(formInline.groupId)"
              @input="$forceUpdate()"
              style="width: 390px"
            >
              <el-option label="全部设备" :value="groupIdOne"></el-option>
              <el-option
                label="未分组设备"
                :value="groupIdTwo"
                :disabled="isNo"
              ></el-option>
              <el-option
                v-for="item in typeListGroup"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="isNo"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消息分类:">
            <el-select
              v-model="formInline.category"
              placeholder="请选择"
              @input="$forceUpdate()"
            >
              <el-option
                v-for="item in typeListMessage"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消息标题:">
            <el-input
              v-model="formInline.title"
              placeholder="请输入内容"
              style="width: 390px"
            ></el-input>
          </el-form-item>
          <el-form-item label="消息内容:">
            <el-input
              v-model="formInline.text"
              placeholder="请输入内容"
              style="width: 390px"
              @input="$forceUpdate()"
              @change="chText"
              id="conXiaoC"
              :disabled="disabled"
            ></el-input>
            <el-button
              type="primary"
              plain
              @click="pushImage1"
              v-if="isShowC"
              style="margin-left: 20px; color: #fff"
              >上传</el-button
            >
          </el-form-item>
          <!-- <el-form-item label="启用状态:">
            <el-select v-model="formInline.state" placeholder="请选择">
              <el-option
                v-for="item in statusOne"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select> 
          </el-form-item> -->
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel1" style="padding: 12px 20px"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="dingChange"
            style="padding: 12px 20px; font-size: 14px; border-radius: 4px"
            >保 存</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 修改上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible1"
      width="30%"
      :before-close="handleCloseImage1"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange1" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传音频弹框 -->
    <el-dialog
      title="上传音频"
      :visible.sync="audioChange"
      width="30%"
      :before-close="handleCloseaAuC"
      append-to-body
    >
      <input
        type="file"
        ref="audio"
        accept=".amr"
        capture="microphone"
        id="pop_audio"
      />
      <!-- <audio id="audioId" controls width="100%"></audio> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="audioChange = false">取 消</el-button>
        <el-button type="primary" @click="uploadAudio1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传视频弹框 -->
    <el-dialog
      title="上传视频"
      :visible.sync="videoChange"
      width="30%"
      :before-close="handleCloseVideC"
      append-to-body
    >
      <input type="file" ref="video" accept="video/*" capture="camcorder" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="videoChange = false">取 消</el-button>
        <el-button type="primary" @click="uploadVideo1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 点击每一行的弹框 -->
    <div>
      <el-dialog
        title="提示"
        :visible.sync="eachDialogVisible"
        width="30%"
        :before-close="handleCloseEach"
      >
        <span v-if="isZHI" style="margin-left: 20px">{{ wenImage }}</span>
        <el-image
          style="width: 200px; height: 200px; margin-left: 30px"
          :src="realImage"
          v-if="isTu"
        ></el-image>
        <!-- <audio
          name="audioOne"
          controls
          controlslist="nodownload"
          :src="yinImage"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          type="audio/mp3"
          v-if="isYIN"
        ></audio> -->
        <video
          controls
          :src="shiImage"
          v-if="isShi"
          style="margin-left: 10px"
          name="videoOne"
        ></video>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import * as qiniu from "qiniu-js";
import BenzAMRRecorder from "benz-amr-recorder";
export default {
  inject: ["reload"],
  data() {
    return {
      clientHeight: document.body.clientHeight,
      xiugai: false,
      xiugai1: false,
      iconPlay: true,
      iconPause: false,
      uState: "",
      //增加的弹框
      isTu: false,
      // isYIN: false,
      isShi: false,
      isZHI: false,
      dialogVisible: false,
      centerDialogVisible: false,
      dialogVisibleChange: false,
      imageVisible: false,
      imageVisible1: false,
      eachDialogVisible: false,
      isShow: false,
      isShowC: false,
      disabled: false,
      audioFrequency: false,
      videoFrequency: false,
      audioChange: false,
      videoChange: false,
      realImage: "",
      yinImage: "",
      shiImage: "",
      wenImage: "",
      value1: "",
      value2: "",
      typeList: [],
      typeListTwo: [],
      typeLists: [],
      typeListname: [],
      typeListGroup: [],
      groupIdOne: 0,
      groupIdTwo: -1,
      statusOne: [],
      isXian: true,
      photoUrls: "",
      unitName: [],
      //性别下拉
      optionssex: [],
      optionssexs: [],
      // optionssexsType:[],
      optionStatus: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "条目编号",
        },
        {
          value: "3",
          label: "分类",
        },
      ],
      overallForm: {
        time: [],
      },
      SerachInput: {
        tiem: null,
        tiems: null,
        type: "",
        phones: "",
        region: "",
        regions: "",
      },
      typeListMessage: [],
      tiem: "",
      tiems: "",
      value: "",
      input: "",
      title: "",
      tableData: [],
      //分页
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      keyIds: [],
      regionsId: null,
      regionsIds: null,
      //增加的表单
      formInline: {
        id: "",
        productId: "",
        type: "",
        groupId: [],
        title: "",
        sendDate: "",
        text: "",
        state: "0",
        createDate: null,
        updateDate: null,
        category: "",
      },
      value3: "",
      value4: "",
      //七牛
      token: "",
      accessKey: "",
      bucket: "",
      secretKey: "",
      prefix: "",
      token1: "",
      accessKey1: "",
      bucket1: "",
      secretKey1: "",
      prefix1: "",
      token2: "",
      accessKey2: "",
      bucket2: "",
      secretKey2: "",
      prefix2: "",
      tokenC: "",
      accessKeyC: "",
      bucketC: "",
      secretKeyC: "",
      prefixC: "",
      tokenY: "",
      accessKeyY: "",
      bucketY: "",
      secretKeyY: "",
      prefixY: "",
      tokenV: "",
      accessKeyV: "",
      bucketV: "",
      secretKeyV: "",
      prefixV: "",
      flag: null,
      tuP: "",
      dingId: "",
      chuxian: "",
      stateC: "",
      tuPC: "",
      // productNum:"",
      messaT: "",
      neirong: "",
      flagChange: false,
      searchId: "",
      chContent: "",
      isWEN: false,
      audioElement: [],
      videoElement: [],
      groupaa: [],
      pageSizes: 10,
      pageCounts: 1, //网络请求的页码
      unitNamePage: 0,
      amr: null,
      bijiao: [],
      seVaArry: [],
      isNo: false,
    };
  },
  created() {
    this.getMessList();
    this.messType();
    this.productType();
    this.groupType();
    this.getSelectList();
    this.category();
    this.audioElement = document.getElementsByName("audioOne");
    this.videoElement = document.getElementsByName("videoOne");
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //数据导出
    dataExport() {
      let that = this;
      that
        .$http({
          url: "/too/center/timedMessage/exportTimedMessageList",
          params: {
            endDate: this.value2,
            id: this.SerachInput.phones,
            productId: this.SerachInput.region,
            startDate: this.value1,
            type: this.SerachInput.regions,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          console.log("导出定时消息推送：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          // const objectUrl = URL.createObjectURL(blob);
          // window.location.href = objectUrl;
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "定时消息推送" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    nameUser(index, row) {
      console.log("状态：", index, row);
      if (row.state == "开启") {
        this.uState = "1";
      } else if (row.state == "关闭") {
        this.uState = "0";
      }
      apiShout
        .getupTimeMessSta({
          id: row.id,
          state: this.uState,
        })
        .then((res) => {
          console.log("状态：", res.data);
          if (res.data.code == 200) {
            this.getMessList();
            this.$message({
              message: "恭喜你,修改状态成功",
              type: "success",
            });
          }
        });
    },
    //增加点击上传图片的按钮
    pushImage() {
      if (this.flag == 3) {
        //图片
        this.imageVisible = true;
        this.tuP = "pmessage";
      } else if (this.flag == 2) {
        //音频
        this.audioFrequency = true;
        this.tuP = "amessage";
      } else if (this.flag == 4) {
        //视频
        this.videoFrequency = true;
        this.tuP = "vmessage";
      }
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: this.tuP }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        if (this.tuP == "pmessage") {
          this.token = res.data.result.token;
          this.accessKey = res.data.result.accessKey;
          this.bucket = res.data.result.bucket;
          this.secretKey = res.data.result.secretKey;
          this.prefix = res.data.result.prefix;
        } else if (this.tuP == "amessage") {
          this.token1 = res.data.result.token;
          this.accessKey1 = res.data.result.accessKey;
          this.bucket1 = res.data.result.bucket;
          this.secretKey1 = res.data.result.secretKey;
          this.prefix1 = res.data.result.prefix;
        } else if (this.tuP == "vmessage") {
          this.token2 = res.data.result.token;
          this.accessKey2 = res.data.result.accessKey;
          this.bucket2 = res.data.result.bucket;
          this.secretKey2 = res.data.result.secretKey;
          this.prefix2 = res.data.result.prefix;
        }
      });
    },
    //增加确定上传图片
    uploadImage() {
      this.imageVisible = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('realBackImage'));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("realPicture", JSON.stringify(res.key));
          loading.close();
          document.getElementById("conXiao").placeholder = res.key;
          // console.log('===:',document.getElementById("conXiao"));
          // document.getElementById("gengaiAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //增加上传头像格式限制
    fileChange(e) {
      // console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      // var file = e.target.files[0];
      // if (window.FileReader) {
      //   var reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   //监听文件读取结束后事件
      //   reader.onloadend = function (e) {
      //     //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
      //     //保存本地
      //     localStorage.setItem("realBackImage", JSON.stringify(e.target.result));
      //   };
      // }
      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = e.target.files[0].size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    //增加上传音频
    uploadAudio() {
      this.audioFrequency = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      // this.fileList = [];
      console.log("===========", this.$refs.audio.files);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('realBackImage'));
      this.id = this.userinfo.id;
      if (this.$refs.audio.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.audio.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token1;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix1 + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("realAudio", JSON.stringify(res.key));
          loading.close();
          document.getElementById("conXiao").placeholder = res.key;
          // document.getElementById("gengaiAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //增加上传视频
    uploadVideo() {
      this.videoFrequency = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      // this.fileList = [];
      console.log("===========", this.$refs.video.files);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('realBackImage'));
      this.id = this.userinfo.id;
      if (this.$refs.video.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.video.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token2;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix2 + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("realVideo", JSON.stringify(res.key));
          loading.close();
          document.getElementById("conXiao").placeholder = res.key;
          // document.getElementById("gengaiAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //修改点击上传图片的按钮
    pushImage1() {
      console.log("this:", this.chuxian);
      if (this.chuxian == "3") {
        //图片
        this.imageVisible1 = true;
        this.tuPC = "pmessage";
      } else if (this.chuxian == "2") {
        //音频
        this.audioChange = true;
        this.tuPC = "amessage";
      } else if (this.chuxian == "4") {
        //视频
        this.videoChange = true;
        this.tuPC = "vmessage";
      }

      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: this.tuPC }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        if (this.tuPC == "pmessage") {
          this.tokenC = res.data.result.token;
          this.accessKeyC = res.data.result.accessKey;
          this.bucketC = res.data.result.bucket;
          this.secretKeyC = res.data.result.secretKey;
          this.prefixC = res.data.result.prefix;
        } else if (this.tuPC == "amessage") {
          this.tokenY = res.data.result.token;
          this.accessKeyY = res.data.result.accessKey;
          this.bucketY = res.data.result.bucket;
          this.secretKeyY = res.data.result.secretKey;
          this.prefixY = res.data.result.prefix;
        } else if (this.tuPC == "vmessage") {
          this.tokenV = res.data.result.token;
          this.accessKeyV = res.data.result.accessKey;
          this.bucketV = res.data.result.bucket;
          this.secretKeyV = res.data.result.secretKey;
          this.prefixV = res.data.result.prefix;
        }
      });
    },
    //修改确定上传图片
    uploadImage1() {
      this.imageVisible1 = false;
      this.flagChange = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      // this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('advertBackImage1'));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.tokenC;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefixC + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("dingCp", JSON.stringify(res.key));
          // this.photoUrl = res.key;
          loading.close();
          document.getElementById("conXiaoC").value = res.key;
          console.log("====", document.getElementById("conXiaoC"));
          // document.getElementById("addAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //修改上传头像格式限制
    fileChange1(e) {
      // console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      // var file = e.target.files[0];
      // if (window.FileReader) {
      //   debugger;
      //   var reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   //监听文件读取结束后事件
      //   reader.onloadend = function (e) {
      //     //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
      //     //保存本地
      //     localStorage.setItem("advertBackImage1",JSON.stringify(e.target.result));
      //   };
      // }
      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = e.target.files[0].size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    //修改确定上传音频
    uploadAudio1() {
      this.audioChange = false;
      this.flagChange = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      // this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('advertBackImage1'));
      this.id = this.userinfo.id;
      if (this.$refs.audio.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.audio.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.tokenY;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefixY + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("realAudio1", JSON.stringify(res.key));
          // this.photoUrl = res.key;
          loading.close();
          document.getElementById("conXiaoC").value = res.key;
          console.log("====", document.getElementById("conXiaoC"));
          // document.getElementById("addAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //修改确定上传视频
    uploadVideo1() {
      this.videoChange = false;
      this.flagChange = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      // this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('advertBackImage1'));
      this.id = this.userinfo.id;
      if (this.$refs.video.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.video.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.tokenV;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefixV + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("realVideo1", JSON.stringify(res.key));
          // this.photoUrl = res.key;
          loading.close();
          document.getElementById("conXiaoC").value = res.key;
          console.log("====", document.getElementById("conXiaoC"));
          // document.getElementById("addAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },

    //查询定时消息列表
    getMessList() {
      apiShout
        .getMessageList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
        })
        .then((res) => {
          console.log("消息列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].state == "1") {
                this.tableData[i].state = "关闭";
              } else if (this.tableData[i].state == "0") {
                this.tableData[i].state = "开启";
              } else {
                this.tableData[i] = "";
              }
            }
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          }
        });
    },
    //开启关闭的下拉框
    switchUse() {
      api.getSelectProduct({ dictCode: "switchstate" }).then((res) => {
        console.log("开启关闭：", res.data);
        if (res.data.code == 200) {
          this.statusOne = res.data.result;
        }
      });
    },
    //消息类型的下拉框
    messType() {
      api.getSelectProduct({ dictCode: "timedmessagetype" }).then((res) => {
        console.log("消息类型：", res.data);
        if (res.data.code == 200) {
          this.typeLists = res.data.result;
          this.typeListname = res.data.result;
        }
      });
    },
    //产品分类的下拉框
    productType() {
      apiShout.getDingProduct().then((res) => {
        console.log("产品分类：", res.data);
        if (res.data.code == 200) {
          this.typeList = res.data.result;
          this.typeListTwo = res.data.result;
        }
      });
    },
    //销售分组 的下拉框
    groupType() {
      apiShout.getGroups().then((res) => {
        console.log("销售分组：", res.data);
        if (res.data.code == 200) {
          this.typeListGroup = res.data.result;
        }
      });
    },
    category() {
      api.getSelectProduct({ dictCode: "timedmessagecategory" }).then((res) => {
        console.log("消息分类：", res.data);
        if (res.data.code == 200) {
          this.typeListMessage = res.data.result;
        }
      });
    },
    //消息类型的选中
    messLei(val) {
      console.log("消息选中：", val);
      this.messaT = val;
      if (val == "2" || val == "3" || val == "4") {
        this.isShow = true;
        this.disabled = true;
        this.isShowC = true;
      } else if (val == "1") {
        this.isShow = false;
        this.disabled = false;
        this.isShowC = false;
      }
      if (val == "3") {
        //图片
        this.flag = 3;
        this.chuxian = "3";
        this.formInline.text = "";
      } else if (val == "4") {
        //视频
        this.flag = 4;
        this.chuxian = "4";
        this.formInline.text = "";
      } else if (val == "2") {
        //音频
        this.flag = 2;
        this.chuxian = "2";
        this.formInline.text = "";
      } else if (val == "1") {
        this.flag = 1;
        this.chuxian = "1";
        // document.getElementById("conXiaoC").placeholder = "";
        this.formInline.text = "";
      }
    },
    //销售分组的选中
    groupLei(aa) {
      // console.log("lalalala:", aa);
      this.xiugai1 = true;
      if (aa.indexOf(0) == -1) {
        this.isNo = false;
        let params = [];
        aa.forEach((item) => {
          params.push({
            timedId: null,
            groupId: item,
          });
        });
        this.groupaa = JSON.stringify(params);
        console.log("销售分组选中：", this.groupaa);
      } else {
        this.isNo = true;
        this.formInline.groupId = [0];
        let params = [];
        this.formInline.groupId.forEach((item) => {
          params.push({
            timedId: null,
            groupId: item,
          });
        });
        this.groupaa = JSON.stringify(params);
        console.log("销售分组选中：", this.groupaa);
      }
    },
    //产品分类的选中
    pruList(val) {
      console.log("产品分类选中：", val);
      if (val == 2 || val == 3 || val == 4) {
        api.getSelectProduct({ dictCode: "timedmessagetype" }).then((res) => {
          console.log("消息类型：", res.data);
          if (res.data.code == 200) {
            let aa = res.data.result.slice(0, 2);
            console.log("1011001:", aa);
            this.typeLists = aa;
            this.typeListname = aa;
            // console.log();
          }
        });
      } else {
        this.messType();
      }
      // this.productNum=val;
    },
    getSelectList() {
      apiShout
        .getProduct({ pageCount: this.pageCounts, pageSize: this.pageSizes })
        .then((res) => {
          const data = [];
          var usrAll = [];
          const keyId = [];
          if (res.data.code == 200) {
            usrAll = res.data.result.data;
            if (usrAll) {
              usrAll.forEach((usr) => {
                keyId.push(usr.id);
                data.push({
                  label: usr.type + " " + usr.name + " " + usr.version,
                  value: usr.id, //userId  phonenumber
                  key: usr.id, //userId  phonenumber
                });
              });
            }
            this.unitNamePage = res.data.result.page.totalCount;
          }
          this.unitName = data;
          this.keyIds = keyId;
          return data;
        });
    },
    // 分页
    handleSizeChanges(size) {
      this.pageSizes = size;
      this.getSelectList();
    },
    handleCurrentChanges(pageCounts) {
      this.pageCounts = pageCounts;
      this.getSelectList();
    },
    chText(val) {
      console.log("修改文字：", val);
      this.isWEN = true;
      this.chContent = val;
      this.$forceUpdate();
    },
    //修改
    details(index, row) {
      console.log(index, row);
      this.xiugai = true;
      this.switchUse();
      this.getSelectList();
      this.dingId = row.id;
      this.dialogVisibleChange = true;
      //查询定时消息详情
      apiShout.getTimeDing({ id: row.id }).then((res) => {
        console.log("查询定时消息详情：", res.data);
        if (res.data.code == 200) {
          this.formInline = res.data.result;
          let formTime = res.data.result.childs;
          let item = formTime;
          let chArry = [];
          let vaArry = [];
          item.forEach((itemOne) => {
            // console.log("每一个：", itemOne);
            chArry.push(itemOne.groupId);
            let conteBtn = {};
            conteBtn.timedId = itemOne.timedId;
            conteBtn.groupId = itemOne.groupId;
            vaArry.push(conteBtn);
          });
          // console.log("取出来的：", chArry);
          this.formInline.groupId = chArry;
          this.bijiao = chArry;
          this.seVaArry = vaArry;
          // console.log("不动的：", this.seVaArry);
          this.value4 = res.data.result.sendDate;
          this.formInline.text = res.data.result.content;
          this.chuxian = res.data.result.type;
          this.neirong = res.data.result.text;
          if (
            res.data.result.type == "3" ||
            res.data.result.type == "4" ||
            res.data.result.type == "2"
          ) {
            this.isShowC = true;
            this.disabled = true;
          } else {
            this.isShowC = false;
            this.disabled = false;
          }
        }
      });
    },
    //修改的确定
    dingChange() {
      this.dialogVisibleChange = false;
      this.getSelectList();
      if (this.flagChange == true) {
        if (this.chuxian == "3") {
          //图片
          let tuC = JSON.parse(localStorage.getItem("dingCp"));
          this.formInline.text = tuC;
        } else if (this.chuxian == "2") {
          //音频
          let tuC = JSON.parse(localStorage.getItem("realAudio1"));
          this.formInline.text = tuC;
        } else if (this.chuxian == "4") {
          //视频
          let tuC = JSON.parse(localStorage.getItem("realVideo1"));
          this.formInline.text = tuC;
        } else if (this.chuxian == "1") {
          this.formInline.text = this.chContent;
          if (this.isWEN == true) {
            if (this.formInline.text == "" || this.formInline.null) {
              this.$message.error("修改失败，消息内容不能为空");
              return false;
            }
          }
        } else {
          this.formInline.text = this.neirong;
          // console.log('22222200000:',this.formInline.text);
          // if (this.chuxian == "2") {
          //   let aa = this.formInline.text.lastIndexOf(".");
          //   let ah = this.formInline.text.substring(aa + 1, aa.length);
          //   if (ah != "mp3" || ah != "cda" || ah != "wav") {
          //     this.$message.error("上传失败，请上传正确的音频格式");
          //     return false;
          //   }
          // } else if (this.chuxian == "3") {
          //   let aa = this.formInline.text.lastIndexOf(".");
          //   let ah = this.formInline.text.substring(aa + 1, aa.length);
          //   // console.log('截取后：',ah);
          //   if (ah != "jpg" || ah != "png" || ah != "webp") {
          //     this.$message.error("上传失败，请上传正确的图片格式");
          //     return false;
          //   }
          // } else if (this.chuxian == "4") {
          //   let aa = this.formInline.text.lastIndexOf(".");
          //   let ah = this.formInline.text.substring(aa + 1, aa.length);
          //   console.log("截取的字符串：", ah);
          //   if (ah !== "mp4" || ah !== "rm" || ah !== "rmvb") {
          //     this.$message.error("上传失败，请上传正确的视频格式");
          //     return false;
          //   } else {
          //     let dd = JSON.parse(localStorage.getItem("realVideo1"));
          //     this.formInline.text = dd;
          //   }
          // }
        }
        // if(this.chuxian=="1"){
        //   this.formInline.text=this.chContent;
        //   if(this.isWEN==true){
        //      if(this.formInline.text==""||this.formInline.null){
        //     this.$message.error("修改失败，消息内容不能为空");
        //     return false;
        //   }
        //   }

        //   console.log('xiuga:',this.chContent);
      }
      // if (this.chuxian == "2") {
      //   let aa = this.formInline.text.lastIndexOf(".");
      //   let ah = this.formInline.text.substring(aa + 1, aa.length);
      //   if (ah != "mp3" || ah != "cda" || ah != "wav") {
      //     this.$message.error("上传失败，请上传正确的音频格式");
      //     return false;
      //   }
      // } else if (this.chuxian == "3") {
      //   let aa = this.formInline.text.lastIndexOf(".");
      //   let ah = this.formInline.text.substring(aa + 1, aa.length);
      //   console.log('截取后：',ah);
      //   if (ah != "jpg" || ah != "png" || ah != "webp") {
      //     this.$message.error("上传失败，请上传正确的图片格式");
      //     return false;
      //   }
      // } else if (this.chuxian == "4") {
      //   let aa = this.formInline.text.lastIndexOf(".");
      //   let ah = this.formInline.text.substring(aa + 1, aa.length);
      //   console.log("截取的字符串：", ah);
      //   if (ah !== "mp4" || ah !== "rm" || ah !== "rmvb") {
      //     this.$message.error("上传失败，请上传正确的视频格式");
      //     return false;
      //   } else {
      //     let dd = JSON.parse(localStorage.getItem("realVideo1"));
      //     this.formInline.text = dd;
      //   }
      // }

      if (this.formInline.text == "" || this.formInline.text == null) {
        this.$message.error("修改失败，消息内容不能为空");
        return false;
      }
      console.log("修改不选：", this.formInline.groupId);
      // if (this.formInline.groupId.length == this.bijiao.length) {
      //   this.groupaa = JSON.stringify(this.seVaArry);
      // }
      // this.formInline.groupId.forEach((ie) => {
      //   console.log("ie", ie);
      //   this.bijiao.forEach((is) => {
      //     console.log("is", is);
      //     if (ie === is&&this.formInline.groupId.length == this.bijiao.length) {
      //       this.groupaa = JSON.stringify(this.seVaArry);
      //     }
      //   });
      // });
      if (this.xiugai == true && this.xiugai1 == false) {
        this.groupaa = JSON.stringify(this.seVaArry);
      }
      // if(this.formInline.state=="开启"){
      //   this.stateC=0;
      // }else{
      //   this.stateC=1;
      // }
      //对于时间的处理
      console.log(this.value4, "value666");
      var d = new Date(this.value4);
      let youWant =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      this.value4 = youWant;
      let obj = {
        id: this.dingId,
        productId: this.formInline.productId,
        type: this.formInline.type, //消息类型
        title: this.formInline.title,
        sendDate: this.value4,
        content: this.formInline.text,
        state: this.formInline.state,
        groups: this.groupaa,
        category: this.formInline.category,
      };
      console.log("chuanrudeshuju:", obj);
      apiShout.getUpTimeMess(obj).then((res) => {
        console.log("修改：", res.data);
        if (res.data.code == 200) {
          this.$message({
            message: "恭喜你,修改定时消息成功",
            type: "success",
          });
          this.xiugai = false;
          this.xiugai1 = false;
          //刷新页面
          this.getMessList();
          this.flagChange = false;
          //清空表单
          this.dingId = "";
          this.formInline.productId = "";
          this.formInline.type = "";
          this.formInline.groupId = "";
          this.formInline.title = "";
          this.formInline.text = "";
          this.formInline.category = "";
          this.value4 = "";
          this.stateC = "";
          localStorage.removeItem("dingCp");
          localStorage.removeItem("realAudio1");
          localStorage.removeItem("realVideo1");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //搜索
    handleQuery() {
      apiShout
        .getMessageList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startDate: this.value1,
          endDate: this.value2,
          id: this.searchId,
          productId: this.SerachInput.region,
          type: this.SerachInput.regions,
        })
        .then((res) => {
          console.log("搜索：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          }
        });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getMessList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getMessList();
    },
    //增加定时消息推送
    addLabel() {
      console.log(document.getElementById("conXiao"), "查询这里输出的");
      if (document.getElementById("conXiao") != null) {
        document.getElementById("conXiao").placeholder = "";
      }
      // if(document.getElementById("conXiao").placeholder !=""){
      //   this.formInline.photoUrl =
      //   "http://cdn.careld.cn/" + JSON.parse(localStorage.getItem("popUp"));
      // }else{
      //   this.formInline.text ="";
      // }
      this.dialogVisible = true;
      this.formInline = {};
      this.value3 = "";
      this.formInline.text = "";
      this.isNo = false;
      this.switchUse();
      this.formInline.state = "0";
      this.getSelectList();
      console.log(this.messaT, "this.messaT");
      if (this.messaT == 2) {
        document.getElementById("pop_audio").value = "";
      } else if (this.messaT == 3) {
        document.getElementById("addTu").value = "";
      } else if (this.messaT == 4) {
        document.getElementById("addShi").value = "";
      }
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.formInline = {};
      document.getElementById("conXiao").placeholder = "";
    },
    cancel1() {
      this.dialogVisibleChange = false;
      this.formInline.productId = "";
      this.formInline.type = "";
      this.formInline.groupId = "";
      this.formInline.title = "";
      this.formInline.text = "";
      this.value4 = "";
      this.value3 = "";
      this.disabled = false;
    },
    //保存
    preservation() {
      console.log(this.formInline.text, "formInline.text");
      console.log("----", this.formInline.state);
      if (this.flag == 3) {
        let tu = JSON.parse(localStorage.getItem("realPicture"));
        this.formInline.text = tu;
      } else if (this.flag == 2) {
        let yin = JSON.parse(localStorage.getItem("realAudio"));
        this.formInline.text = yin;
      } else if (this.flag == 4) {
        let shi = JSON.parse(localStorage.getItem("realVideo"));
        this.formInline.text = shi;
      } else if (this.flag == 1) {
        let wen = this.formInline.text;
        this.formInline.text = wen;
      }
      if (this.formInline.text == "" || this.formInline.text == null) {
        this.$message.error("增加定时消息推送失败，消息内容不能为空");
        return false;
      }
      if (document.getElementById("conXiao").placeholder == "") {
        this.formInline.text = "";
        console.log("走这里啊");
      }
      let aa = this.formInline.text.lastIndexOf(".");
      let bb = this.formInline.text.slice(aa + 1);
      console.log("20202:", bb);
      if (this.flag == 2 && bb != "amr") {
        this.$message.error("增加失败，音频格式应为amr格式");
        return false;
      }
      if (
        (this.flag == 1 && this.formInline.state == "") ||
        (this.flag == 2 && this.formInline.state == "") ||
        (this.flag == 3 && this.formInline.state == "") ||
        (this.flag == 4 && this.formInline.state == "")
      ) {
        this.$message.error("增加失败，请选择启用状态");
        return false;
      }

      let obj = {
        productId: this.formInline.productId,
        type: this.formInline.type, //消息类型
        title: this.formInline.title,
        sendDate: this.value3,
        content: this.formInline.text,
        state: this.formInline.state,
        groups: this.groupaa,
        category: this.formInline.category,
      };
      let formParams = JSON.parse(JSON.stringify(obj));
      delete formParams.childs;
      console.log("传入的数据：", formParams);
      apiShout.getInterMaeesge(formParams).then((res) => {
        console.log("增加定时消息推送：", res.data);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            message: "恭喜你,增加定时消息成功",
            type: "success",
          });
          //刷新页面
          this.getMessList();
          //清空表格
          this.formInline.productId = "";
          this.formInline.type = "";
          this.formInline.groupId = "";
          this.formInline.title = "";
          this.formInline.text = "";
          this.formInline.category = "";
          this.value3 = "";
          this.disabled = false;
          document.getElementById("conXiao").placeholder = "";
          localStorage.removeItem("realPicture");
          localStorage.removeItem("realAudio");
          localStorage.removeItem("realVideo");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    rowPlay(index, row) {
      if (this.amr !== null) {
        this.stopPlayVoice();
      }
      this.amr = new BenzAMRRecorder(); //建立
      console.log(this.amr);
      var filePath = row.content; // mp3的地址
      filePath = "https:" + filePath.split(":")[1];
      console.log(filePath, "查看播放链接");
      //⚠️注意跨域问题
      this.amr
        .initWithUrl(filePath) //初始化
        .then(() => {
          console.log(this.amr.isPlaying(), "查看111");
          this.amr.play(); //播放
          console.log(";;;;走这里了");
          this.amr.onEnded(() => {
            // this.msg = "点击播放";
            console.log(";;;;走这里了2");
          });
        })
        .catch(() => {
          // this.msg = "点击播放";
          this.$message.error("播放录音失败");
        });
      console.log(index, row, "停止");
    },
    //停止播放
    stopPlayVoice() {
      if (this.amr.isPlaying()) {
        console.log(this.amr.isPlaying(), "停止播放");
        this.amr.stop();
        // this.amr = null;
      }
    },
    //点击表格事件
    rowClick(index, row) {
      console.log("点击表格：", index, row);
      if (row.type == "图片消息") {
        this.eachDialogVisible = true;
        this.realImage = row.content;
        this.isTu = true;
        this.isYIN = false;
        this.isShi = false;
        this.isZHI = false;
      } else if (row.type == "音频消息") {
        this.yinImage = row.content;
        this.isTu = false;
        this.isYIN = true;
        this.isShi = false;
        this.isZHI = false;
      } else if (row.type == "视频消息") {
        this.eachDialogVisible = true;
        this.shiImage = row.content;
        this.isShi = true;
        this.isTu = false;
        this.isYIN = false;
        this.isZHI = false;
      } else if (row.type == "文字消息") {
        this.eachDialogVisible = true;
        this.wenImage = row.content;
        this.isZHI = true;
        this.isTu = false;
        this.isYIN = false;
        this.isShi = false;
      }
    },
    changeId(val) {
      this.searchId = val;
    },
    handleCloseImage() {
      this.imageVisible = false;
    },
    handleCloseaAuFre() {
      this.audioFrequency = false;
    },
    handleCloseVideFre() {
      this.videoFrequency = false;
    },
    handleCloseImage1() {
      this.imageVisible1 = false;
    },
    handleCloseaAuC() {
      this.audioChange = false;
    },
    handleCloseVideC() {
      this.videoChange = false;
    },
    handleCloseEach() {
      this.eachDialogVisible = false;
      const audioElement = this.audioElement;
      if (audioElement && audioElement.length > 0) {
        for (let i = 0; i < audioElement.length; i++) {
          this.audioElement[i].pause();
        }
      }
      const videoElement = this.videoElement;
      if (videoElement && videoElement.length > 0) {
        for (let i = 0; i < videoElement.length; i++) {
          this.videoElement[i].pause();
        }
      }
    },
    handleClose() {
      this.dialogVisibleChange = false;
      this.formInline.productId = "";
      this.formInline.type = "";
      this.formInline.groupId = "";
      this.formInline.title = "";
      this.formInline.text = "";
      this.value3 = "";
      this.value4 = "";
      this.disabled = false;
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item__label {
  text-align: left;
  color: #909399;
  padding: 0 3px 0 0;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-form-item {
  margin-right: 30px;
}
.el-button--primary {
  background-color: #d78673;
  border-color: #d78673;
  border-radius: 22px;
  padding: 8px 22px;
}
.fclass {
  background: none;
  color: rgb(90, 88, 88);
  border: 0px;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  margin: 20px 40px 20px 25px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.el-table td.el-table__cell div {
  color: #909499;
}
.bottom-right {
  float: right;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  .el-button {
    float: right;
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 18px;
  font-weight: bold;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
.el-button--text {
  color: #8c8e91;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
}
.el-button {
  padding: 8px 22px;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>